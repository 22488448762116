// Generated by Framer (13d9867)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
const MaterialFonts = getFonts(Material);

const enabledGestures = {WE0xmNllm: {hover: true, pressed: true}};

const serializationHash = "framer-h8mol"

const variantClassNames = {WE0xmNllm: "framer-v-17uzvi"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "WE0xmNllm", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-17uzvi", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"WE0xmNllm"} ref={ref ?? ref1} style={{"--border-bottom-width": "0px", "--border-color": "rgba(0, 0, 0, 0)", "--border-left-width": "0px", "--border-right-width": "0px", "--border-style": "solid", "--border-top-width": "0px", backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 50, borderTopRightRadius: 50, ...style}} variants={{"WE0xmNllm-hover": {"--border-bottom-width": "1px", "--border-color": "rgb(119, 85, 204)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px"}, "WE0xmNllm-pressed": {backgroundColor: "rgb(0, 0, 0)"}}} {...addPropertyOverrides({"WE0xmNllm-hover": {"data-border": true, "data-framer-name": undefined}, "WE0xmNllm-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-oxszen-container"} layoutDependency={layoutDependency} layoutId={"fji29RPAN-container"}><Material color={"rgb(68, 85, 187)"} height={"100%"} iconSearch={"Home"} iconSelection={"ArrowBack"} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"fji29RPAN"} layoutId={"fji29RPAN"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({"WE0xmNllm-hover": {color: "rgb(119, 85, 204)"}, "WE0xmNllm-pressed": {color: "rgb(255, 255, 255)"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-h8mol.framer-1nm8kq7, .framer-h8mol .framer-1nm8kq7 { display: block; }", ".framer-h8mol.framer-17uzvi { cursor: pointer; height: 32px; overflow: hidden; position: relative; width: 32px; will-change: var(--framer-will-change-override, transform); }", ".framer-h8mol .framer-oxszen-container { flex: none; height: 16px; left: calc(50.00000000000002% - 16px / 2); position: absolute; top: calc(50.00000000000002% - 16px / 2); width: 16px; }", ".framer-h8mol[data-border=\"true\"]::after, .framer-h8mol [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"xac7RENMD":{"layout":["fixed","fixed"]},"gDBm1VD25":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramertllJESPwE: React.ComponentType<Props> = withCSS(Component, css, "framer-h8mol") as typeof Component;
export default FramertllJESPwE;

FramertllJESPwE.displayName = "Backward Arrow";

FramertllJESPwE.defaultProps = {height: 32, width: 32};

addFonts(FramertllJESPwE, [{explicitInter: true, fonts: []}, ...MaterialFonts], {supportsExplicitInterCodegen: true})